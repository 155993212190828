import checkForGlobalSettings from '~/utils/checkForGlobalSettings'

export default () => {
  const gqlRootNames: string[] = [...checkForGlobalSettings().gqlRootNames]
  const gqlChunks: string[][] = [...checkForGlobalSettings().gqlChunks]
  const gqlProps: (string | undefined)[] = [...checkForGlobalSettings().gqlProps]
  const gqlMeta: boolean[] = [...checkForGlobalSettings().gqlMeta]

  const clearGqlFields = () => {
    gqlRootNames.length = 0
    gqlChunks.length = 0
    gqlProps.length = 0
    gqlMeta.length = 0
  }

  return { gqlRootNames, gqlChunks, gqlProps, gqlMeta, clearGqlFields }
}
