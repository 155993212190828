export default () => {
  const mainNavigation = useMainNavigation()
  const footerNavigation = useFooterNavigation()
  const globalSetting = useGlobalSetting()
  const categories = useArticleCategories()
  const podcasts = usePodcasts()

  const gqlRootNames: string[] = []
  const gqlChunks: string[][] = []
  const gqlProps: (string | undefined)[] = []
  const gqlMeta: boolean[] = []

  !Object.keys(globalSetting.value).length &&
    gqlRootNames.push('globalSetting') &&
    gqlChunks.push(['globalData', 'socialNetworks', 'supportUs', 'payQR', 'seo']) &&
    gqlProps.push(undefined) &&
    gqlMeta.push(false)
  !mainNavigation.value?.length &&
    gqlRootNames.push('nav1:renderNavigation') &&
    gqlChunks.push(['navigation']) &&
    gqlProps.push(`navigationIdOrSlug: "main-navigation"\ntype: TREE\nmenuOnly: true`) &&
    gqlMeta.push(false)
  !footerNavigation.value?.length &&
    gqlRootNames.push('nav2:renderNavigation') &&
    gqlChunks.push(['navigation']) &&
    gqlProps.push(`navigationIdOrSlug: "footer-navigation"\ntype: TREE\nmenuOnly: true`) &&
    gqlMeta.push(false)
  !Object.keys(podcasts.value).length &&
    gqlRootNames.push('podcast') &&
    gqlChunks.push(['basic', 'feeds']) &&
    gqlProps.push(undefined) &&
    gqlMeta.push(false)
  !categories.value?.length && gqlRootNames.push('categories') && gqlChunks.push(['basic', 'seo']) && gqlProps.push(undefined) && gqlMeta.push(false)

  return { gqlRootNames, gqlChunks, gqlProps, gqlMeta }
}
